<template lang="pug">
.lot-card(
  v-if="lot && (!lot.isHidden || enableHiddenLots)"
  :class="[selected && '_selected', adaptive && '_adaptive', lot?.app?.isArchived && '_archived', (!isTmManager && byTemplate && lot.IsSeen === false) && '_unseen']"
)
  .info-block
    .tags-line(v-if="isAuth")
      .line(
        v-for="tag of lotTagsColors"
        :key="'user-lot-tag' + lot?.lotId + tag.id"
        :style="`background-color: ${tag.color}`"
      )
    .checkbox-block(v-if="isAuth && (enableLotManagement || tags.length) && !roleReadOnly")
      slot(name="checkbox")
    .main-block(:class="(!isAuth || simpleCard) && 'nonauth'")
      .lot-card__info-block
        .string-block__lot-number
          slot(name="template-info")
          span.lot-item__status-value._4(v-if="lot?.app?.isArchived") Архив
          slot(name="lot-item__status")
            span.lot-item__status-value(v-if="lot.status" :class="`_${lot?.status?.id}`") {{ lot?.status?.title }}
          .lot-card__link
            a(v-if="hideDetailLink" :href="lot.link" target="_blank") № {{ lot.govRuId }}
            lot-link(v-else :id="lot.lotId" :number="lot.govRuId")
            span.lot-item__status-value._5(v-if="lot.isMultipleLots" style="width: fit-content;") ЛОТ № {{ lot.lotNumber }}

          tags-selector(v-if="isAuth && !isTmManager && !lot.app?.isArchived && !roleReadOnly && !simpleCard" v-model="lot")

          span.unseen-tag.lot-item__status-value(v-if="!isTmManager && byTemplate && lot.IsSeen === false") Новая закупка
          span.unseen-tag.lot-item__status-value.seen(v-if="!isTmManager && byTemplate && lot.IsSeen === 'seen'") Просмотрено

        .string-block__title-wrapper
          slot(name="title")

        string-block-mini(
          :lot="lot"
          :show-keyword-entries="showKeywordEntries"
          :show-etp-number="showEtpNumber"
          :show-docs-fts="showDocsFts"
          :docs-keywords="docsKeywords"
          @show-entry-keywords="$emit('showEntryKeywords')"
        )

      .lot-card__cost-block
        lot-cost(:lot="lot" :type="type")
          template(#price)
            slot(name="price")

      .lot-card__interaction(v-if="isAuth && !simpleCard")
        .lot-card__interaction-block
          form-calculation-block(v-if="showCalcForm && !isTmManager" :id="lot.lotId" :govRuId="lot.govRuId" :lotNumber="lot.isMultipleLots ? lot.lotNumber : -1")
          slot(name="status")
        .lot-card__interaction-block(:class="isTmManager && 'row'")
          slot(name="responsible-person")
          lot-actions(:lot="lot" :by-template="byTemplate" :show-unseen-button="showUnseenButton" :hide-detail-link="hideDetailLink" @mark-viewed="$emit('markViewed')")

  .comment-block
    span.hidden-lot.lot-item__status-value(v-if="enableHiddenLots && lot.isHidden") Скрытая закупка
    slot(name="dates")
    comments(v-if="isAuth && enableComments && !simpleCard" :lot="lot" :field="type" :readonly="isTmManager || lot?.app?.isArchived || roleReadOnly")
    lot-actions.nonauth-actions(v-if="!isAuth || simpleCard" :lot="lot" :by-template="byTemplate" :show-unseen-button="showUnseenButton" :is-fast-search="simpleCard" :hide-detail-link="hideDetailLink" @mark-viewed="$emit('markViewed')")
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import { logMessage } from "@/utils/logger/logger";
import useAuthStore from "~/stores/auth/useAuthStore";
import useSearchStore from "~/stores/search/useSearchStore";

import StringBlockMini from "@/components/mainStreamPage/lotItem/StringBlockMini.vue";
import FormCalculationBlock from "@/components/mainStreamPage/lotItem/FormCalculationBlock.vue";
import Comments from "@/components/comments/Comments.vue";
import LotLink from "@/components/ui/links/LotLink.vue";
import LotCost from "~/components/cards/modules/LotCost.vue";
import LotActions from "~/components/cards/modules/LotActions.vue";
import TagsSelector from "~/components/tags/TagsSelector.vue";

import type { PropType } from "vue";
import type { LotItemInterface } from "@/stores/search/LotItemInterface";

export default defineComponent({
  name: "LotCard",
  components: {
    TagsSelector,
    LotActions,
    LotCost,
    LotLink,
    Comments,
    FormCalculationBlock,
    StringBlockMini,
  },
  props: {
    lot: {
      type: Object as PropType<LotItemInterface>,
      default: () => ({}),
    },
    byTemplate: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String as PropType<'tmLot'|'app'|'contract'>,
      default: 'tmLot',
    },
    showEtpNumber: {
      type: Boolean,
      default: false,
    },
    showKeywordEntries: {
      type: Boolean,
      default: false,
    },
    showUnseenButton: {
      type: Boolean,
      default: false,
    },
    showCalcForm: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    adaptive: {
      type: Boolean,
      default: false,
    },
    simpleCard: {
      type: Boolean,
      default: false,
    },
    hideDetailLink: {
      type: Boolean,
      default: false,
    },
    showDocsFts: {
      type: Boolean,
      default: false,
    },
    docsKeywords: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  emits: [
    'markViewed',
    'showEntryKeywords',
    'showCreateContract',
  ],
  setup(props) {

    const route = useRoute();
    const { isAuth, isTmManager, roleReadOnly, enableComments, enableLotManagement, enableHiddenLots } = useUserAccess();

    const searchStore = useSearchStore();
    const { tags, tagsMapped } = storeToRefs(searchStore);

    if (props.lot?.tmLot?.tags?.length) {
      props.lot.tmLot.tags.forEach(tag => {
        if (!tags.value.find(e => e.id === tag.id)) tags.value.push({ id: tag.id, title: tag.title, color: tag.color })
      })
    }

    if (props.lot.isHidden && !enableHiddenLots.value) {
      console.log('UID, companyId', useAuthStore()?.me?.id, useAuthStore()?.company?.id)
      console.log('lotId', props.lot?.lotId)
      logMessage(`HIDDEN LOTS SHOWN`)
    }

    const lotTagsColors = computed(() => (props.lot?.tmLot?.tags || []).map(e => ({ id: e.id, color: tagsMapped.value[e.id]?.color || e.color })))

    return {
      routeName: route.name as string,
      tags,
      lotTagsColors,
      isAuth,
      isTmManager,
      roleReadOnly,
      enableComments,
      enableHiddenLots,
      enableLotManagement,
    };
  },
});
</script>

<style lang="scss">
.main-block {
  b {
    font-weight: normal;
    background-color: var(--keyword-background);
  }
}
</style>

<style scoped lang="scss">
@import "@/assets/styles/elements/tag";
@import "@/assets/styles/lots/searchLotCard";

.lot-card {
  width: 100%;
  max-width: var(--main-content-width);
  height: fit-content;
  box-sizing: border-box;
  border: 1px solid var(--lot-border-color);
  background-color: var(--main-color-white);

  &._selected {
    background-color: #f0f9eb;
  }

  &._archived {
    background-color: #fafafa;
  }

  &._unseen {
    background-color: #fff6ec;
  }

  .lot-card__info-block {
    display: flex;
    flex-flow: column;
    gap: 0;
  }

  .info-block {
    display: flex;
    flex-flow: row;
  }

  .checkbox-block {
    display: flex;
  }

  .comment-block {
    display: flex;
    flex-flow: column;
    font-size: 15px;
    line-height: 18px;
    padding: 16px 12px;
    border-left: 1px solid var(--lot-border-color);
    box-sizing: border-box;
  }

  .string-block__lot-number {
    display: flex;
    flex-flow: row wrap;
    gap: 8px;
    font-size: 16px;
    align-items: center;
  }

  .lot-card__link {
    display: flex;
    gap: 8px;
    font-size: 13px;
    line-height: 20px;
    text-decoration: none;
    color: var(--main-color-blue);
  }

  .tags-line {
    display: grid;
    width: 6px;
  }

  .hidden-lot {
    width: 100%;
    margin-bottom: 4px;
    background-color: #8b0000;
    color: white;
    font-weight: bold;
  }

  .unseen-tag, .hidden-lot {
    color: black;
    background-color: #d9ecff;

    &.seen {
      background-color: #e0fcf3;
      color: #0f6f4f;
    }
  }

  .nonauth-actions {
    justify-content: flex-start;
    margin-top: auto;
  }
}
</style>
