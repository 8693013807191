<template lang="pug">
.string-block-mini
  .more-info
    span.label Заказчик:
    analytic-link.value(v-if="lot?.customers" tab="customers" :company="lot.customers[0]")
    span.unknown(v-else) Заказчик неизвестен

  .more-info
    span.label Тип закупки:
    span.value(v-if="lot?.type?.title") {{ lot?.type?.title }}
    span.unknown(v-else) Вид закупки неизвестен

  .more-info
    span.label Регион закупки:
    span.value(v-if="lot?.region?.title") {{ lot?.region?.title }}
    span.unknown(v-else) Регион закупки неизвестен

  .more-info
    span.label Закон:
    span.value(v-if="lot?.law?.title") {{ lot?.law?.title }}
    span.unknown(v-else) Закон неизвестен

  .more-info
    span.label Площадка:
    a.link.value(v-if="lot?.auctionSite?.link" target="_blank" :href="lot.auctionSite.link") {{ lot.auctionSite.title }}
    span.unknown(v-else) Площадка не известна

  .more-info(v-if="showEtpNumber && lot.etpNumber")
    span.label № на площадке:
    span.value {{ lot.etpNumber }}

  template(v-if="showKeywordEntries")

    .more-info(v-if="lot?.FTS?.purchaseObjects && lot?.FTS?.purchaseObjects.includes(b)")
      span.label Объекты закупки:
      div.value.wrap-anywhere(v-html="lot.FTS.purchaseObjects.split('...', 1)[0]")

    .more-info(v-if="lot?.FTS?.okpdCodes && lot?.FTS?.okpdCodes.includes(b)")
      span.label Коды ОКПД:
      div.value(v-html="lot.FTS.okpdCodes.split('...', 1)[0]")

    .more-info(v-if="lot?.FTS?.deliveryPlace && lot?.FTS?.deliveryPlace.includes(b)")
      span.label Места поставки:
      div.value(v-html="lot.FTS.deliveryPlace.split('...', 1)[0]")

    template(v-if="showDocsFts")
      .more-info(v-if="docsFTSData || checked")
        span.label Документация:
        div.value(v-if="docsFTSData" v-html="docsFTSData")
        div.value.unknown(v-else) Вхождений ключевых слов в документации не найдено, вероятно тендер найден по другим критериям поиска
      .fetch-docs(v-else @click="checkDocsFTS")
        span.span-fetch-docs Проверить вхождения ключевых слов в файлах документации
        mini-loader(v-if="docsLoader")

    .keywords(v-if="checkFTS()")
      a.link.margin(@click="$emit('showEntryKeywords')") Просмотреть
      |
      | все вхождения ключевых слов объектах закупки и кодах окпд.
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useApi } from "~/use/api/useApi";

import type { PropType } from "vue";
import type { LotItemInterface } from "@/stores/search/LotItemInterface";

import MiniLoader from "~/components/ui/loader/MiniLoader.vue";
import AnalyticLink from "@/components/ui/links/AnalyticLink.vue";

export default defineComponent({
  name: "StringBlockMini",
  components: {
    MiniLoader,
    AnalyticLink,
  },
  emits: [
    "showEntryKeywords",
  ],
  props: {
    lot: {
      type: Object as PropType<LotItemInterface>,
      default: () => ({}),
    },
    showKeywordEntries: {
      type: Boolean,
      default: false,
    },
    showDocsFts: {
      type: Boolean,
      default: false,
    },
    docsKeywords: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    showEtpNumber: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {

    const b = '<b>';

    const checked = ref(false)
    const docsFTSData = ref(props.lot?.FTS?.docsSearch || '');
    const docsLoader = ref(false);

    function checkFTS() {
      if (props.lot && props.lot.FTS) {
        return Object.entries(props.lot.FTS).some(([key, value]) => !!value && value.includes('<b>') && (value.split('...', 2).length > 1));
      }
    }

    // todo сделать это на беке
    function checkDocsFTS() {
      if (!props.docsKeywords.length || docsLoader.value) return

      docsLoader.value = true
      useApi().lots.getDocsFTS<any[]>(props.lot?.lotId, { docsSearch: { keywords: props.docsKeywords }})
        .then((data) => {
          if (data?.length) {
            const occurrences = data[0]?.occurrences?.[0]
            docsFTSData.value = occurrences
            if (props.lot?.FTS) props.lot.FTS.docsSearch = occurrences
          } else checked.value = true
        })
        .catch(() => {})
        .finally(() => docsLoader.value = false)
    }

    return {
      b,
      checked,
      docsFTSData,
      docsLoader,
      checkFTS,
      checkDocsFTS,
    }
  }
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/links";
@import "@/assets/styles/lots/stringBlockMini";

.link {
  @include link-mixin;
  line-height: 18px;
}

.fetch-docs {
  @include link-mixin;

  display: flex;
  flex-flow: row;
  gap: 4px;
  line-height: 18px;
}

.margin {
  margin: 0;
}

.wrap-anywhere {
  overflow-wrap: anywhere;
}
</style>
